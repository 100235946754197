import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import * as React from "react";
import { Link as Scroll } from "react-scroll";
import { ImLocation } from "react-icons/im";
import { BiPhoneCall } from "react-icons/bi";
import { AiTwotoneMail } from "react-icons/ai";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="fotterContainer">
        <ul className="socialFooter">
          <li>
            <a
              href="https://www.facebook.com/artifiapps"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebookF className="footer-fb" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/artifiapps"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter className="footer-tw" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/artifiapps_kolkata/"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram className="footer-gram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/artifiapps"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn className="footer-in-sm" />
            </a>
          </li>
        </ul>
        <div className="footerWrap">
          <div className="contact">
            <ul className="add">
              <h2 style={{ color: "white", marginBottom: "2px" }}>
                Important Links
              </h2>
              <div className="underBar" style={{ marginBottom: "25px" }}></div>
              <li>
                <Scroll to="about" smooth={true} offset={-50} duration={1000}>
                  <a
                    aria-label="About Us"
                    href="#alout"
                    style={{
                      cursor: "pointer",
                      color: "#ffffff",
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    About Us
                  </a>
                </Scroll>
              </li>
              <li>
                <Scroll to="service" smooth={true} offset={100} duration={1000}>
                  <a
                    aria-label="Services"
                    href="#servicing"
                    style={{
                      cursor: "pointer",
                      color: "#ffffff",
                      textDecoration: "none",
                    }}
                  >
                    Services
                  </a>
                </Scroll>
              </li>
              <li>
                <a
                  style={{
                    cursor: "pointer",
                    color: "#ffffff",
                    textDecoration: "none",
                  }}
                  href="/portfolio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Portfolio
                </a>
              </li>
            </ul>
            <ul className="add">
              <h2 style={{ color: "white", marginBottom: "2px" }}>
                Featured Services
              </h2>
              <div className="underBar" style={{ marginBottom: "25px" }}></div>
              <li>Android application development</li>
              <li>iPhone application development</li>
              <li>Website Design and Development</li>
              <li>Chatbot Development</li>
              <li>SEO and Digital Marketing</li>
            </ul>
            <div className="addTable">
              <h2 style={{ paddingLeft: "7px", marginBottom: "2px" }}>
                Contact Us
              </h2>
              <div className="underBar" style={{ marginLeft: "7px" }}></div>
              <div className="tablewrap">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <ImLocation
                      style={{
                        fontSize: "1.8rem",
                        marginRight: "7px",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                  <div>
                    58/5 Dr Jiban Ratan Dhar Road
                    <br />
                    Kolkata-700028, West Bengal, India
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: " row",
                    marginRight: "7px",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <BiPhoneCall
                      style={{ fontSize: "1.5rem", marginTop: "5px" }}
                    />
                  </div>
                  <div style={{ margin: "7px" }}>
                    {" "}
                    <a
                      aria-label="Artifiapps Phone Number"
                      rel="noopener noreferrer"
                      href="https://wa.me/+919007580780"
                      target="_blank"
                      style={{
                        color: "white",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      +919007580780
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <AiTwotoneMail
                      style={{
                        fontSize: "1.5rem",
                        marginRight: "7px",
                        marginTop: "5px",
                      }}
                    />
                  </div>{" "}
                  <div>
                    {" "}
                    <a
                      rel="noopener noreferrer"
                      href="mailto:hello@artifiapps.com"
                      style={{ color: "white", textDecoration: "none" }}
                      aria-label="Artifiapps Email-id"
                    >
                      hello@artifiapps.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <iframe
              title="Artifiapps Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.340581743431!2d88.42335221496081!3d22.641086885147566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeee8a3c1cafcf690!2sArtifiapps-%20Mobile%20apps%20development%2C%20Website%20development%2C%20eCommerce%20development%20company%20in%20Kolkata!5e0!3m2!1sen!2sin!4v1644573582322!5m2!1sen!2sin"
              width="100%"
              height="300px"
              style={{ border: 0, marginTop: "2rem" }}
              loading="lazy"
            ></iframe>
          </div>
          <div></div>
        </div>
      </div>
      <p
        style={{
          padding: "2rem",
          color: "#ffffff",
          marginTop: "10px",
          textAlign: "center",
        }}
      >
        &#169; Artifiapps designed and developed by
        <a
          href="/portfolio"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#ffffff",
            textDecorationLine: "none",
            marginLeft: "5px",
          }}
        >
          Krishnendu Dasgupta
        </a>
      </p>
    </footer>
  );
};

export default Footer;
