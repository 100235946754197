import React, { Component } from "react";
import logo from "../images/artifilogo1.svg";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { BiPhoneCall, BiUpArrow } from "react-icons/bi";
import { AiTwotoneMail } from "react-icons/ai";
import { Link as Scroll } from "react-scroll";
import { Helmet } from "react-helmet";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      clicked2: false,
      navbar: false,
      value: "",
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.changeBackground.bind(this));
  };

  changeBackground = () => {
    if (window.scrollY >= 70) {
      this.setState({ navbar: true });
    } else {
      this.setState({ navbar: false });
    }
  };

  render() {
    const handleClick = () => {
      this.setState({ clicked: !this.state.clicked });
    };
    const handleClick2 = () => {
      this.setState({ clicked2: !this.state.clicked2 });
    };

    return (
      <div name="home">
        <Helmet>
          <meta charSet="utf-8" />
          <html lang="en" amp />
          <title>
            Artifiapps | Website and Mobile application development services
          </title>
          <meta
            name="description"
            content="We are expertise in software development services like Website, eCommerce, WordPress, Android apps, iOS apps, CRM, SEO Optimization, Chatbot and more. We can build fully optimized and performative Website and Mobile applications for you. We are hooked with your business success and growth."
          />
          <link rel="canonical" href="https://artifiapps.com/" />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <header
          className={this.state.navbar ? "header activeHeader" : "header"}
        >
          <nav className="menu-lg" id="sweethome">
            <div className="menucompo">
              <div className="logo">
                <img
                  src={logo}
                  height={40}
                  width={60}
                  alt="artifiapps_logo"
                  loading="eager"
                  placeholder="blurred"
                />
                <a
                  href="/"
                  aria-label="Artifiapps best software devlopment company in kolkata"
                  title="Artifiapps best software devlopment company in kolkata"
                >
                  &nbsp;Artifiapps
                </a>
              </div>
              <div
                className={
                  this.state.clicked2 ? "menu-back-open1" : "menu-back1"
                }
              >
                <div className="menuBg">
                  <ul className="menuPhone">
                    <li style={{ fontSize: "1.8rem" }}>
                      <a
                        aria-label="Artifiapps Phone Number"
                        title="Artifiapps Contact Number"
                        rel="noopener noreferrer"
                        href="https://wa.me/+919007580780"
                        target="_blank"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <BiPhoneCall style={{ fontSize: "1.8rem" }} /> &nbsp;
                        Call: +919007580780
                      </a>
                    </li>
                    <li style={{ fontSize: "1.8rem" }}>
                      <a
                        title="Artifiapps Email-id"
                        aria-label="Artifiapps Email-id"
                        rel="noopener noreferrer"
                        href="mailto:hello@artifiapps.com"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <AiTwotoneMail style={{ fontSize: "1.8rem" }} /> &nbsp;
                        Email: hello@artifiapps.com
                      </a>
                    </li>
                  </ul>
                  <p
                    style={{
                      fontSize: "1.8rem",
                      color: "#ffffff",
                      marginBottom: "5%",
                    }}
                  >
                    Mon-Sat, Opening Hours: 9:00 Am, Closing Hours: 8:00 Pm
                  </p>
                  <ul className="socialFooter">
                    <li style={{ margin: "2rem" }}>
                      <a
                        title="Facebook"
                        href="https://www.facebook.com/artifiapps"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Facebook"
                      >
                        <FaFacebookF className="footer-fb" />
                      </a>
                    </li>
                    <li style={{ margin: "2rem" }}>
                      <a
                        title="twitter"
                        href="https://twitter.com/artifiapps"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Twitter"
                      >
                        <FaTwitter className="footer-tw" />
                      </a>
                    </li>
                    <li style={{ margin: "2rem" }}>
                      <a
                        title="Instagram"
                        href="https://www.instagram.com/artifiapps_kolkata/"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Instagram"
                      >
                        <FaInstagram className="footer-gram" />
                      </a>
                    </li>
                    <li style={{ margin: "2rem" }}>
                      <a
                        title="LinkedIn"
                        href="https://www.linkedin.com/company/artifiapps"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="LinkedIn"
                      >
                        <FaLinkedinIn className="footer-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={this.state.clicked ? "menu-back-open" : "menu-back"}
              >
                <div className="menusmall">
                  <ul className="navcompo navMenu">
                    <li>
                      <a href="#sweethome">Home</a>
                    </li>
                    <li>
                      <Scroll
                        to="about"
                        activeClass="activelink"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        onClick={handleClick}
                        href="#alout"
                      >
                        About
                      </Scroll>
                    </li>
                    <li>
                      <Scroll
                        to="service"
                        activeClass="activelink"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500}
                        onClick={handleClick}
                      >
                        <a href="#servicing">Services</a>
                      </Scroll>
                    </li>
                    <li>
                      <Scroll
                        to="portfol"
                        activeClass="activelink"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        onClick={handleClick}
                        href="#podfol"
                      >
                        Portfolio
                      </Scroll>
                    </li>
                    <li>
                      <Scroll
                        to="contact"
                        activeClass="activelink"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration={500}
                        onClick={handleClick}
                        href="#contacta"
                      >
                        Contact
                      </Scroll>
                    </li>
                    <li className="dot"></li>
                  </ul>
                  <ul className="menuPhoneSmall">
                    <li
                      style={{
                        fontSize: "1.2rem",
                        listStyle: "none",
                        marginBottom: "10px",
                      }}
                    >
                      <a
                        title="Artifiapps Contact Number"
                        rel="noopener noreferrer"
                        href="https://wa.me/+919007580780"
                        target="_blank"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <BiPhoneCall style={{ fontSize: "1.2rem" }} /> &nbsp;
                        Call: +919007580780
                      </a>
                    </li>
                    <li style={{ fontSize: "1.2rem", listStyle: "none" }}>
                      <a
                        title="Artifiapps Email-id"
                        rel="noopener noreferrer"
                        href="mailto:hello@artifiapps.com"
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <AiTwotoneMail style={{ fontSize: "1.2rem" }} /> &nbsp;
                        Email: hello@artifiapps.com
                      </a>
                    </li>
                  </ul>
                  <p className="time-small">
                    Mon-Sat, Opening Hours: 9:00 Am,
                    <br /> Closing Hours: 8:00 Pm
                  </p>
                  <ul className="socialFooterSmall">
                    <li>
                      <a
                        title="Facebook"
                        href="https://www.facebook.com/artifiapps"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Facebook"
                      >
                        <FaFacebookF className="footer-fb-sm" />
                      </a>
                    </li>
                    <li>
                      <a
                        title="twitter"
                        href="https://twitter.com/artifiapps"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Twitter"
                      >
                        <FaTwitter className="footer-tw-sm" />
                      </a>
                    </li>
                    <li>
                      <a
                        title="Instagram"
                        href="https://www.instagram.com/artifiapps_kolkata/"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="Instagram"
                      >
                        <FaInstagram className="footer-gram-sm" />
                      </a>
                    </li>
                    <li>
                      <a
                        title="LinkedIn"
                        href="https://www.linkedin.com/company/artifiapps"
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label="LinkedIn"
                      >
                        <FaLinkedinIn className="footer-in-sm" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="switch">
                <button
                  aria-label="menu"
                  className="fancy-burger"
                  onClick={handleClick}
                >
                  <span className="box"></span>
                  <span
                    className={
                      this.state.clicked
                        ? "rectangle rectangle--small top-open"
                        : "rectangle rectangle--top rectangle--small"
                    }
                  ></span>
                  <span
                    className={
                      this.state.clicked
                        ? "rectangle middle-open"
                        : "rectangle rectangle--middle"
                    }
                  ></span>
                  <span
                    className={
                      this.state.clicked
                        ? "rectangle rectangle--small bottom-open"
                        : "rectangle rectangle--bottom rectangle--small"
                    }
                  ></span>
                </button>
              </div>
              <div className="email">
                <button
                  aria-label="email"
                  className="fancy-burger2"
                  onClick={handleClick2}
                >
                  <span
                    className={
                      this.state.clicked2
                        ? "rectangle rectangle--small top-open"
                        : "rectangle rectangle--top rectangle--small"
                    }
                  ></span>
                  <span
                    className={
                      this.state.clicked2
                        ? "rectangle middle-open"
                        : "rectangle rectangle--middle"
                    }
                  ></span>
                  <span
                    className={
                      this.state.clicked2
                        ? "rectangle rectangle--small bottom-open"
                        : "rectangle rectangle--bottom rectangle--small"
                    }
                  ></span>
                </button>
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
