import * as React from "react";
import "../../styles/styles.css";
import "aos/dist/aos.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Slider from "../components/Slider";

// markup
const IndexPage = () => {
  return (
    <main>
      <Header />
      <Slider />
      <Footer />
    </main>
  );
};

export default IndexPage;
